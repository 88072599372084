<template>
  <div>
    <loading
      :active.sync="isLoading"
      color="#f8a229"
      loader="dots"
      :width="loadingSize"
      :height="loadingSize"
      :is-full-page="loadingFullPage"
    />
    <v-card-text>
      <v-container class="row1">
        <v-row>
          <v-col cols="14" sm="4" md="2">
            <v-row dense class="mb-5">
              <!-- <router-link
                v-bind:to="'/admin/admin-bills'"
                style="text-decoration: none;"
              >
                <v-btn color="secondary">
                  <v-icon color="black">
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </router-link> -->
            </v-row></v-col
          >
          <v-col cols="14" sm="8" md="10">
            <v-row dense justify="end" class="mb-5">
              <router-link v-bind:to="'/home'" style="text-decoration: none">
                <v-btn color="secondary" dark @click="dialog = true">
                  <v-icon color="black"> mdi-cart </v-icon>
                  <v-card-text class="black--text font-weight-bold">
                    Add Order
                  </v-card-text>
                </v-btn>
              </router-link>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col cols="14" sm="12" md="12">
            <material-card icon="mdi-receipt" icon-small color="secondary">
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :search="search"
                :headers="headers"
                :items="bills"
                :options.sync="options"
                :server-items-length="totalItems"
                :loading="tableLoading"
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                item-key="billNo"
                show-expand
                class="elevation-1"
                :items-per-page="1"
                :footer-props="{
                  showFirstLastPage: true,
                  'items-per-page-options': [1, 5, 10, 15],
                }"
                @item-expanded="getPaymentInfo"
              >
                <template v-slot:expanded-item="{ item }">
                  <td style="background-color: whitesmoke" colspan="1">
                    <div v-if="item.orderType == 1">
                      Name
                      <br />

                      Phone
                      <br />
                      Address
                      <br />
                      Post Code
                    </div>
                  </td>

                  <td style="background-color: whitesmoke" colspan="2">
                    <div
                      v-if="item.orderType == 1"
                      style="
                        justify-content: right;
                        text-transform: uppercase;
                        font-weight: bold;
                      "
                    >
                      : {{ item.custName }}
                      <br />

                      : {{ item.deliveryContactNo }}
                      <br />
                      : {{ item.deliveryAddress }}
                      <br />
                      : {{ item.deliveryPostCode }}
                    </div>
                  </td>

                  <td
                    style="background-color: whitesmoke; text-align: right"
                    colspan="2"
                  >
                    <v-container>
                      <v-chip
                        label
                        color="primary"
                        class="white--text ml-5 mt-2"
                        style="
                          justify-content: right;
                          text-transform: uppercase;
                          font-weight: bold;
                        "
                      >
                        Payments Info

                        <v-icon class="ml-2" color="white">
                          mdi-arrow-right-thin-circle-outline
                        </v-icon>
                      </v-chip>
                    </v-container>
                  </td>

                  <td
                    style="background-color: whitesmoke; text-align: right"
                    colspan="3"
                  >
                    <v-chip
                      label
                      color="primary"
                      class="white--text ml-5 mt-2"
                      style="
                        justify-content: right;
                        text-transform: uppercase;
                        font-weight: bold;
                      "
                      v-if="totalPaid == item.billTotal"
                    >
                      Paid

                      <v-icon class="ml-2" color="white">
                        mdi-check-circle
                      </v-icon>
                    </v-chip>

                    <v-chip
                      label
                      color="red"
                      class="white--text ml-5 mt-2"
                      style="
                        justify-content: right;
                        text-transform: uppercase;
                        font-weight: bold;
                      "
                      v-if="totalPaid > 0 && totalPaid != item.billTotal"
                    >
                      Not Paid
                      <v-icon class="ml-2" color="white">
                        mdi-close-circle
                      </v-icon>
                    </v-chip>

                    <table v-for="(payment, index) in payments" :key="index">
                      <tr>
                        <th>Payment {{ index + 1 }}</th>
                        <td>
                          <v-chip
                            label
                            color="secondary"
                            outlined
                            class="black--text ml-5 mb-2 mt-2"
                            style="
                              width: 150px;
                              justify-content: right;
                              text-transform: uppercase;
                              font-weight: bold;
                            "
                          >
                            {{ payment.payMethod }} :
                            {{ currencyFormat(payment.payAmount) }}
                          </v-chip>
                        </td>
                      </tr>
                    </table>

                    <v-chip
                      label
                      color="secondary"
                      outlined
                      class="black--text ml-5 mt-2"
                      v-if="payments.length == 0"
                    >
                      No Payments
                    </v-chip>
                  </td>
                </template>

                <template v-slot:[`item.orderType`]="{ item }">
                  <div>
                    <v-chip
                      label
                      outlined
                      color="primary"
                      style="color: white; width: 100%"
                      v-if="item.orderType === 0"
                    >
                      <v-icon left> mdi-food-takeout-box </v-icon>
                      Pick Up
                    </v-chip>
                    <v-chip
                      label
                      outlined
                      color="primary"
                      style="color: white; width: 100%"
                      v-if="item.orderType === 1"
                    >
                      <v-icon left> mdi-bike-fast </v-icon>
                      Delivery
                    </v-chip>
                    <v-chip
                      label
                      outlined
                      color="primary"
                      style="color: white; width: 100%"
                      v-if="item.orderType === 2"
                    >
                      <v-icon left> mdi-food-fork-drink </v-icon>
                      Dine In
                    </v-chip>
                  </div>
                </template>

                <template v-slot:[`item.createdAt`]="{ item }">
                  {{ item.createdAt | localDate }}
                </template>
                <template v-slot:[`item.billTotal`]="{ item }">
                  {{ currencyFormat(item.billTotal) }}
                </template>

                <template v-slot:[`item.delete`]="{ item }">
                  <v-row>
                    <div @click="editInvoice(item)" class="mr-3">
                      <v-icon color="info"> mdi-pencil-outline </v-icon>
                    </div>
                    <div @click="deleteInvoice(item)">
                      <v-icon color="red"> mdi-trash-can-outline </v-icon>
                    </div>
                  </v-row>
                </template>
                <!-- <template v-slot:[`item.billNo`]="{ item }">
                  <v-chip
                    label
                    color="black"
                    style="color: white"
                    @click="$router.push(`bills/${item.billNo}`)"
                  >
                    Invoice
                    <v-icon color="secondary"> mdi-bill </v-icon>
                  </v-chip>
                </template> -->

                <template v-slot:[`item.details`]="{ item }">
                  <v-chip
                    label
                    color="black"
                    style="color: white"
                    @click="$router.push(`admin-bills/${item.billNo}`)"
                  >
                    Invoice
                    <v-icon color="secondary" class="ml-2">
                      mdi-receipt-text
                    </v-icon>
                  </v-chip>
                </template>
              </v-data-table>
            </material-card>
            <!-- <v-row class="mt-5" justify="center">
              <v-col cols="8" sm="8" md="8">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  @click="changePage"
                  circle
                  color="black"
                ></v-pagination>
              </v-col>
            </v-row>  -->
          </v-col></v-row
        >
      </v-container>
      <v-container
        ><v-col cols="14" sm="8" md="4" class="float-right">
          <v-row dense class="mb-5 float-right">
            <v-btn color="secondary" @click="dialogTrash = true">
              <v-icon color="black"> mdi-delete-restore </v-icon>
            </v-btn>
          </v-row></v-col
        ></v-container
      >
    </v-card-text>

    <v-dialog v-model="paymentView" max-width="500px" origin="top right">
      <v-card>
        <v-row style="float: right; margin-top: 10px; margin-right: 10px">
          <div>
            <v-icon color="closeButton" @click="paymentView = false">
              mdi-close-circle
            </v-icon>
          </div>
        </v-row>

        <v-card-title>
          <span class="headline"> Payment</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="billPaymentForm">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    readonly
                    v-model="billPayment.billNo"
                    label="Bill No"
                    outlined
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="billPayment.payMethod"
                    label="Payment Method"
                    :items="payMethod"
                    item-text="text"
                    item-value="value"
                    outlined
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="billPayment.payAmount"
                    label="Pay Amount"
                    outlined
                    clearable
                    type="number"
                    class="numberInput"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="paymentView = false">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="validate"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="dialogDelete" max-width="450px">
      <v-card>
        <v-row style="float: right; margin-top: 10px; margin-right: 10px">
          <div>
            <v-icon color="closeButton" @click="dialogDelete = false">
              mdi-close-circle
            </v-icon>
          </div>
        </v-row>
        <v-card-title style="word-break: normal" class="headline ">
          <span style="font-size: 13px; font-weight: bold">
            Are you confirm to delete?
          </span>
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="dialogDelete = false">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="trashItem">
            Confirm
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <v-dialog v-model="dialogTrash" max-width="1200">
      <v-card class="pa-10">
        <v-row style="float: right">
          <div>
            <v-icon color="closeButton" @click="dialogTrash = false">
              mdi-close-circle
            </v-icon>
          </div>
        </v-row>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        >
        </v-text-field>

        <v-data-table
          :headers="headers"
          :items="trashed"
          :items-per-page="5"
          :search="search"
        >
          <template v-slot:[`item.orderType`]="{ item }">
            <div>
              <v-chip
                label
                outlined
                color="primary"
                style="color: white; width: 100%"
                v-if="item.orderType === 0"
              >
                <v-icon left> mdi-food-takeout-box </v-icon>
                Pick Up
              </v-chip>
              <v-chip
                label
                outlined
                color="primary"
                style="color: white; width: 100%"
                v-if="item.orderType === 1"
              >
                <v-icon left> mdi-bike-fast </v-icon>
                Delivery
              </v-chip>
              <v-chip
                label
                outlined
                color="primary"
                style="color: white; width: 100%"
                v-if="item.orderType === 2"
              >
                <v-icon left> mdi-food-fork-drink </v-icon>
                Dine In
              </v-chip>
            </div>
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | localDate }}
          </template>
          <template v-slot:[`item.billTotal`]="{ item }">
            {{ currencyFormat(item.billTotal) }}
          </template>

          <template v-slot:[`item.restore`]="{ item }">
            <v-icon color="secondary" @click="restoreItem(item)">
              mdi-restore
            </v-icon>
          </template>

          <template v-slot:[`item.delete`]="{ item }">
            <v-icon color="#FF0000" @click="permanentDelete(item)">
              mdi-trash-can-outline
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogRestore" max-width="500px">
      <v-card>
        <v-row style="float: right; margin-top: 10px; margin-right: 10px">
          <div>
            <v-icon color="closeButton" @click="dialogRestore = false">
              mdi-close-circle
            </v-icon>
          </div>
        </v-row>
        <v-card-title class="headline">
          Are you sure you want to restore (Bill No - {{ editedItem.billNo }}) ?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="dialogRestore = false">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="restoreItemConfm">
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-row style="float: right; margin-top: 10px; margin-right: 10px">
          <div>
            <v-icon color="closeButton" @click="dialogDelete = false">
              mdi-close-circle
            </v-icon>
          </div>
        </v-row>
        <v-card-title class="headline">
          Are you sure you want to delete (Bill No - {{ editedItem.billNo }}) ?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="dialogDelete = false">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="trashItem"> OK </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPermanentDelete" max-width="500px">
      <v-card>
        <v-row style="float: right; margin-top: 10px; margin-right: 10px">
          <div>
            <v-icon color="closeButton" @click="dialogPermanentDelete = false">
              mdi-close-circle
            </v-icon>
          </div>
        </v-row>
        <v-card-title class="headline">
          Are you sure you want to delete (Bill No - {{ editedItem.billNo }})
          permanently?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="dialogPermanentDelete = false"
          >
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
var moment = require("moment");

export default {
  components: { Loading },
  data() {
    return {
      search: "",
      isLoading: false,
      loadingSize: 50,
      loadingFullPage: true,
      loading: false,
      tableLoading: false,
      options: {},
      totalItems: 0,

      expanded: [],
      singleExpand: true,

      bills: [],
      trashed: [],
      page: 1,
      pageCount: null,
      start: 0,
      end: 7,
      dataFiltered: [],

      headers: [
        // { text: "Invoice Id", value: "billHeaderId", sortable: false },
        { text: "Invoice", value: "billNo" },

        { text: "Order Type", value: "orderType" },
        { text: "Invoice Date", value: "createdAt" },

        { text: "Customer", value: "custName" },
        { text: "Amount", value: "billTotal" },
        // { text: "deliveryAddress", value: "deliveryAddress" },
        // { text: "deliveryPostCode", value: "deliveryPostCode" },

        { text: "", value: "details" },
        { text: "", value: "restore" },
        { text: "", value: "delete" },
        { text: "", value: "data-table-expand" },
      ],
      paymentView: false,
      billPayment: {
        billPaymentNo: 0,
        billNo: 0,
        payMethod: "card",
        payAmount: 0,
      },

      payMethod: [
        { text: "Card ", value: "card" },
        { text: "Cash ", value: "cash" },
        { text: "Credit ", value: "credit" },
        { text: "Cheque ", value: "cheque" },
        { text: "Voucher ", value: "voucher" },
      ],
      payments: [],
      amountPaid: 0,
      totalPaid: 0,
      editedItem: "",
      dialogDelete: false,
      dialogPermanentDelete: false,
      dialogRestore: false,
      dialogTrash: false,
      awaitingSearch: null,
    };
  },
  created() {
    this.$http.get("/BillHeader/GetTrashed").then((response) => {
      this.trashed = response.data.data;
    });
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    search: {
      handler() {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = setTimeout(() => {
          this.options.page = 1;
          this.getDataFromApi();
        }, 1000);
      },
    },
  },
  filters: {
    // moment: function(date) {
    //   return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    // },
    localDate: function (date) {
      return moment.utc(date).local().format("MMM Do YYYY - h:mm a");
    },
  },

  methods: {
    editInvoice(item) {
      this.$router.push(`/cart/${item.billNo}`);
    },
    deleteInvoice(item) {
      this.editedItemIndex = this.bills.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialogDelete = true;
    },
    trashItem() {
      console.log(this.editedItem);

      this.isLoading = true;
      this.$http
        .post(`/BillHeader/Trash/`, this.editedItem)
        .then((response) => {
          if (response.status == 200) {
            this.$toast.success("Deleted Succefully", "Success", {
              position: "topRight",
            });
            this.isLoading = false;
            this.dialogDelete = false;
            this.bills.splice(this.editedItemIndex, 1);
            this.trashed.unshift(this.editedItem);
          } else {
            this.isLoading = false;
            this.$toast.success(response.data.message, "Error", {
              position: "topRight",
            });
          }

          // this.getDataFromApi();
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    restoreItem(item) {
      this.editedIndexFiltered = this.trashed.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogRestore = true;
    },
    permanentDelete(item) {
      this.editedIndexFiltered = this.trashed.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogPermanentDelete = true;
    },

    restoreItemConfm() {
      this.isLoading = true;
      this.$http
        .post("/BillHeader/Restore", this.editedItem)
        .then((response) => {
          if (response.data.success === true) {
            this.trashed.splice(this.editedIndexFiltered, 1);

            this.bills.unshift(response.data.data);

            this.isLoading = false;
            // this.updateData();
            this.$toast.success("Restore successfully.", "Success", {
              position: "topRight",
            });
          } else {
            this.isLoading = false;
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            this.isLoading = false;
            this.$toast.error(" Restore Process Failed", "Error", {
              position: "topRight",
            });
          }
        });

      this.dialogRestore = false;
    },
    deleteItemConfirm() {
      this.isLoading = true;
      var itemId = this.editedItem.billHeaderId;
      this.$http
        .delete("/BillHeader/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            this.trashed.splice(this.editedIndexFiltered, 1);
            this.isLoading = false;
            this.$toast.success("Deleted Permanently.", "Success", {
              position: "topRight",
            });
          }
        })

        .catch((error) => {
          if (error.response.status === 404) {
            this.isLoading = false;
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        });
      this.dialogPermanentDelete = false;
    },

    currencyFormat(val) {
      return val ? "$" + parseFloat(val).toFixed(2) : "$0.00";
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    getPaymentInfo(item) {
      // console.log("itemOld", item);

      item.payments = { sample_accession: "lol" };

      this.isLoading = true;
      this.totalPaid = 0;
      this.$http
        .get(`/BillPayment/GetByBillNo/${item.item.billNo}`)
        .then((response) => {
          this.payments = response.data.data;

          for (var i = 0; this.payments.length > i; i++) {
            console.log(this.payments[i].payAmount);
            this.totalPaid = this.totalPaid + this.payments[i].payAmount;
          }

          this.isLoading = false;
        });
    },
    getDataFromApi() {
      this.isLoading = true;
      this.tableLoading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      var data = {
        pageNumber: page,
        pageSize: itemsPerPage,
        search: this.search,
      };
      this.$http
        .post("/BillHeader/GetAllByFilter", data)
        .then((response) => {
          this.bills = response.data.data.items;
          this.totalItems = response.data.data.totalCount;
          this.isLoading = false;
          this.tableLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.tableLoading = false;
          if (error) {
            this.$toast.error("Error in getting Data", "Error", {
              position: "topRight",
            });
          }
        });
    },
    addPayment(item) {
      console.log("this.bill", item);
      this.amountPaid = 0;
      this.isLoading = true;
      this.billPayment.billNo = item.billNo;
      this.billPayment.billPaymentNo = item.billNo;

      this.$http
        .get(`/BillPayment/GetByBillNo/${item.billNo}`)
        .then((response) => {
          this.payments = response.data.data;
          console.log("Payment", this.payments);

          for (var i = 0; this.payments.length > i; i++) {
            this.amountPaid = this.amountPaid + this.payments[i].payAmount;
          }

          if (this.payments.length > 0) {
            this.billPayment.payAmount = item.billTotal - this.amountPaid;
          } else {
            this.billPayment.payAmount = item.billTotal;
          }
          this.isLoading = false;
          this.paymentView = true;
        });
    },
    validate() {
      if (this.billPayment.payAmount > 0) {
        this.save();
      } else {
        this.$toast.error("Enter the valid amount", "Error", {
          position: "topRight",
        });
      }

      console.log("Bill Payment", this.billPayment);
    },
    save() {
      this.isLoading = true;
      this.$http
        .post("/BillPayment", this.billPayment)
        .then((response) => {
          if (response.data.success === true) {
            // this.addOnItems.unshift(response.data.data);

            console.log("Payment", response);
            this.paymentView = false;
            this.isLoading = false;
            this.$toast.success("Payment added successfully.", "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            this.$toast.error("Payment Process Failed", "Error", {
              position: "topRight",
            });
          }
        });
    },
  },
};
</script>
<style>
.numberInput input[type="number"] {
  -moz-appearance: textfield;
}
.numberInput input::-webkit-outer-spin-button,
.numberInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
